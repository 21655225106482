(($) => {

	/**
	 * Gulp include scripts
	 *
	 * @link https://www.npmjs.com/package/gulp-include
	 */
	/**
	 * Custom block Javascript.
	 * - Remove this file if not needed.
	 * - This file should contain all the necessary JS for your block to
	 *   function independently of this website.
	 */
	console.log('Hello example block!');
	

	/**
	 * jQuery Document Ready
	 *
	 * @link https://learn.jquery.com/using-jquery-core/document-ready/
	 */
	$(() => {
		var w = $(window).width();
		if (w > 1199) {
			$(window).on('scroll', function() {
				if ($(window).scrollTop() > 50) {
					$('.secondary-links').hide();
					$('.desktop-nav .logo svg').width(148).height(50);
				} 
			});
			$(window).on('scroll', function() {
				if ($(window).scrollTop() < 50) {
					$('.secondary-links').show();
					$('.desktop-nav .logo svg').width(219).height(74);
				}
			});
		}
		
		$('.hamburger').click(function(){
			let status = $(this).attr('aria-expanded');
			if (status == 'true') {
				$(this).attr('aria-expanded', false);
				$(this).siblings('.mobile-menu').removeClass('active');
			} else {
				$(this).attr('aria-expanded', true);
				$(this).siblings('.mobile-menu').addClass('active');
			}
		});

		$('.js-mobile-sublinks').click(function(){
			let parent =  $(this).closest('li');
			if (parent.hasClass('active')) {
				parent.removeClass('active');
			} else {
				parent.siblings().removeClass('active');
				parent.addClass('active');
			}
		});

		$('.js-sublinks').parent().hover(
			function() {
				$(this).addClass('active');
			}, function() {
				$(this).removeClass('active');
			}
		);

		$('.js-accordion-toggle').click(function(){
			let item = $(this).closest('li');
			if (item.hasClass('active')) {
				item.removeClass('active');
			} else {
				item.addClass('active');
			}
		});


		if ($('.testimonial-slider').length) {
			$('.testimonial-slider').slick({
				dots: true,
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"></button>',
				nextArrow: '<button type="button" class="slick-next" aria-label="Next"></button>',
			});
		}
		
		jQuery(document).on('gform_post_render', function(event, form_id, current_page){
			// Inline label forms: Add active class if input has value
			$('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').each(function () {
				if ($(this).val().length) {
					$(this).closest('.gfield').addClass('active');
				}
			});

			$('.js-gform select').on('change', function() {
				if ($(this).val() === "") {
					$(this).closest('.gfield').removeClass('active');
				} else {
					$(this).closest('.gfield').addClass('active');
				}
			});

			// Inline label forms: Add active class on focus
			$('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').on('focus', function () {
				$(this).closest('.gfield').addClass('active');
			});

			// Inline label forms: Remove active class on blur
			$('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').on('blur', function () {
				if (!$(this).val().length || $(this).val().includes('_')) {
					$(this).closest('.gfield').removeClass('active');
				}
			});
		});
	});

})(jQuery);

	/* Block-Gallery JS */ 
	let currentRow = 1; // Start with the second row
		
	document.getElementById('view-more').addEventListener('click', function() {
		if (currentRow < allChunksData.allChunks.length) {
			const galleryRows = document.getElementById('gallery-rows');

			// Create a new row element and apply the reverse-gallery class based on row index
			const newRow = document.createElement('div');
			newRow.className = "row " + ((currentRow % 2 === 1) ? 'reverse-gallery' : '');

			// Append images from the next chunk
			allChunksData.allChunks[currentRow].forEach((image, index) => {
				const galleryItem = document.createElement('div');
				galleryItem.className = "col gallery-item " + 
					((currentRow % 2 === 1 && (index === 0 || index === 3)) ? 'full-height' : 
					 ((currentRow % 2 === 0 && index === 1) ? 'full-height' : ''));
				galleryItem.innerHTML = `<a href="${image.url}" data-fancybox="gallery" data-caption="${image.alt}">
											<img src="${image.url}" alt="${image.alt}" />
										</a>`;
				newRow.appendChild(galleryItem);
			});

			// Add the new row to the gallery
			galleryRows.appendChild(newRow);
			currentRow++;

			// Hide the button if there are no more rows to display
			if (currentRow >= allChunksData.allChunks.length) {
				this.style.display = 'none';
			}
		}
	});

	var viewMore = document.getElementById('view-more');
		if (currentRow >= allChunksData.allChunks.length) {
			viewMore.style.display = 'none';
		}	
	

	$('[data-fancybox="gallery"]').fancybox({
	});